import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, merge, Subject, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const DEFAULT_TITLE = 'IXFI';

@Injectable({
    providedIn: 'root'
})
export class PageTitleService implements OnDestroy {
    title$ = new Subject<string>(); //initial value for the title
    subscriptions: Subscription[] = [];

    private titleRoute$: Observable<string | undefined> = //emit any title value defined in the current route
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.getPageTitle(this.activatedRoute.firstChild))
        );

    private titleState$ = merge(this.title$, this.titleRoute$).pipe(
        //listen to either title$ or titleRoute$ values
        filter(title => {
            const is_Undefined = title !== undefined;
            if (!is_Undefined) this.setTitle(`${DEFAULT_TITLE}`);
            return is_Undefined;
        }),
        tap(title => this.setTitle(`${title}`))
    );

    constructor(
        private title: Title,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private meta: Meta,
        @Inject(DOCUMENT) private _document: Document
    ) {
        this.subscriptions.push(this.titleState$.subscribe());
    }

    private getPageTitle(activatedRoute: ActivatedRoute | null): string | undefined {
        while (activatedRoute) {
            if (activatedRoute.firstChild) {
                activatedRoute = activatedRoute.firstChild;
            } else if (activatedRoute.snapshot?.data['title']) {
                const tags = activatedRoute.snapshot.data['metaTags'];
                this.updateDynamicMeta(tags);
                return activatedRoute.snapshot.data['title'] as string;
            } else {
                return undefined;
            }
        }
        return undefined;
    }

    setTitle(pageTitle: string) {
        this.title.setTitle(`${pageTitle}`);
        this.meta.updateTag({ name: 'og_title', content: `${pageTitle}` });
    }

    updateCanonical(url) {
        if (url) {
            this.meta.updateTag({ name: 'canonical', content: environment.BASE_APP_URL + url });
        }
    }

    updateDynamicMeta(tags) {
        if (tags && Object.keys(tags).length) {
            for (const tag in tags) {
                if (tag == 'og_description') {
                    this.meta.updateTag({ name: 'description', content: tags[tag] });
                } 
                    this.meta.updateTag({ name: tag, content: tags[tag] });
            }
        }
    }

    addSchemaMarkupforLandingPage(schemaJson: string, renderer2): void {
        if (environment.production){
            const existingSchema = this._document.querySelector('script[type="application/ld+json"]');
            if (existingSchema) {
                existingSchema.remove();
            }
            let script = renderer2.createElement('script');
            script.type = `application/ld+json`;
            script.text = schemaJson
            renderer2.appendChild(this._document.body, script);
        }
    }

    addSchemaMarkup(schemaName: string, schemaUrl:string, renderer2): void {
        const schemaData = 
            {
                "@context":"https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "IXFI",
                "item": environment.IXFI_EXCHANGE
                },{
                "@type": "ListItem",
                "position": 2,
                "name": schemaName,
                "item": environment.IXFI_EXCHANGE + schemaUrl
                }]
            }
        if (environment.production){
            const existingSchema = this._document.querySelector('script[type="application/ld+json"]');
            if (existingSchema) {
                existingSchema.remove();
            }
            let script = renderer2.createElement('script');
            script.type = `application/ld+json`;
            script.text = JSON.stringify(schemaData);
            renderer2.appendChild(this._document.body, script);
        }                
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
